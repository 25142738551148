body {
  margin: 100;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border-collapse: collapse; /* Ensures borders between cells are collapsed into a single border */
  border: 2px dashed #000; /* Adds a dashed border around the table */
  width: 75%; /* Ensures the table takes up the full width of its container */
  border-spacing: 10px; /* Adjust the spacing between columns */
  background: #fff;
  margin-top: 5vh;
}

th, td {
  border: 2px dashed #000; /* Adds a dashed border to each cell */
  padding: 10px; /* Adds space within each cell */
}

thead th {
  border-bottom: 2px dashed #000; /* Adds a dashed border at the bottom of the table header */
}

tbody tr {
  border-bottom: 2px dashed #000; /* Adds a dashed line between rows */
}

.navigation {
  background-color: #ebecf0;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.footer {
  background-color: #ebecf0;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.footer-image {
  width: 3vh;
  height: auto;
  margin: 0 3vw; /* Horizontal space between images */
}

.header-image {
  width: 8vw;
  height: auto;
}

.download-image {
  width: 2vw;
  height: auto;
}

.banner {
  background-color: red;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  position: fixed;
  top: 7vh;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.popupbanner {
  background-color: white;
  color: black;
  text-align: center;
  padding: 20px;
  font-size: 40px;
  font-weight: bold;
  position: fixed;
  top: 50vh;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.start {
  text-align: center;
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #e8e4e4;
}

.main{
  height: 100vh;
}

.content{
  margin-top: 8vh;
  margin-bottom: 12vh;
}

.page {
  display: flex;
  min-height: 100vh;
  box-sizing: border-box;
}

.info {
  text-align: center;
}

.left, .right {
  flex: 1;
  padding: 20px;
}

.left {
  background-color: #cfc9c9; /* Light gray background for left side */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 9vh; /* height of the header */
  padding-bottom: 9vh; /* height of the footer */
}

.right {
  background-color: #ffffff; /* White background for right side */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 9vh; /* height of the header */
  padding-bottom: 9vh; /* height of the footer */
}

textarea {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.box {
  margin: 2vh 0; /* Adjust top and bottom margin */
  padding: 10px; /* Adjust padding around the textarea */
  border-radius: 4px; /* Optional: Adds rounded corners */
  background-color: #ffffff; /* Optional: Background color for the container */
  resize: none; /* Prevent resizing */
}

.console-wrapper {
  display: flex;
  position: relative;
  width: 80%;
  height: 50vh;
  margin-bottom: 2vh;
}

.console {
  display: flex;
  position: relative;
  width: 100%;
  height: 50vh; /* Ensure both .console and .line-numbers have the same height */
}

.console-textarea {
  display: flex;
  width: 100%;
  height: 50vh;
  background-color: #1e1e1e;
  color: #00ff00;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 8px;
  border: 1px solid #333;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative;
  resize: none; /* Prevent resizing */
}

.console-textarea::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.console-textarea::-webkit-scrollbar-track {
  background: #333; /* Dark background for the track */
  border-radius: 10px; /* Rounded corners for the track */
}

.console-textarea::-webkit-scrollbar-thumb {
  background-color: #00ff00; /* Neon green for the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #1e1e1e; /* Adds space around the thumb and a dark border */
}

.console-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #00cc00; /* Slightly darker green when hovering */
}

.console-textarea::-webkit-scrollbar-corner {
  background-color: #333; /* Matches the track's background */
}

.line-numbers {
  background-color: #1e1e1e;
  color: #888;
  text-align: right;
  user-select: none;
  white-space: nowrap;
  border-radius: 8px 0 0 8px; /* Rounded corners on the left side */
  border-right: 1px solid #333; /* Border to separate line numbers from the textarea */
  box-sizing: border-box; /* Include padding in the width calculation */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  width: 2vw; /* Adjust width as needed */
  height: 100%; /* Full height to match textarea */
  margin-top: 1vh;
  resize: none; /* Prevent resizing */
}

.console-content {
  flex-grow: 1;
  padding: 15px 20px;
  overflow-y: auto;
  white-space: pre-wrap;
  position: relative;
  box-sizing: border-box;
}

.console-content::after {
  content: '|';
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: #00ff00;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* Webkit scrollbar styling for the console content */
.console-content::-webkit-scrollbar {
  width: 8px;
}

.console-content::-webkit-scrollbar-track {
  background-color: #2e2e2e;
}

.console-content::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 8px;
}

/* Add numbers dynamically using JavaScript */
.line-numbers div {
  line-height: 1.5em; /* Adjust line height to match the console content */
}

.nav-button {
  padding: 15px 40px; /* Increase padding for thickness and width */
  font-size: 18px; /* Increase font size for better readability */
  cursor: pointer;
  margin: 10px 10px;
  border: 2px solid #000; /* Adding a border for more emphasis */
  border-radius: 5px; /* Optional: to make the corners rounded */
  background-color: #fffefe; /* Button background color */
  color: #000; /* Text color */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.nav-button:hover {
  background-color: #000; /* Baby blue shade for hover effect */
  color: #fff; /* Baby blue shade for hover effect */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.dap-button {
  padding: 15px 40px; /* Increase padding for thickness and width */
  font-size: 18px; /* Increase font size for better readability */
  cursor: pointer;
  margin: 10px 10px;
  border: 2px solid #fff; /* Border color to match the new background */
  border-radius: 5px; /* Optional: to make the corners rounded */
  background-color: #000; /* Button background color */
  color: #fff; /* Text color */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.dap-button:hover {
  background-color: #fff; /* Background color for hover effect */
  color: #000; /* Text color for hover effect */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.show {
  opacity: 1;
}

.explanation-area {
  margin-top: 2vh;
}

.explanation-box {
  width: 40vw;
  height: 50vh; /* Adjust as needed */
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 10px;
  font-size: 14px;
  resize: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  .start {
    background-size: cover; /* Ensures the background covers the entire element */
    background-position: center; /* Centers the background image */
  }
}

.mobile-message {
  display: none; /* Hide by default */
}

@media screen and (max-width: 600px) {
  .mobile-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
}

.hide-on-mobile {
  display: block; /* Show by default */
}

@media screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none; /* Hide on mobile */
  }

  .header-image {
    width: 100px;
    height: auto;
  }
}





